import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from "rxjs";

import { ConfigService } from "../../../core/services/config.service";
import { LanguageService } from "../../../core/services/language.service";
import { VisibilityService } from "../../../core/services/visibility.service";

@Component({
  selector: 'app-introduction',
  templateUrl: './introduction.component.html',
  styleUrl: './introduction.component.scss'
})
export class IntroductionComponent implements OnInit, OnDestroy {
    helloHeader: string = "Hello, ";
    assistHeader: string = "How may I assist you today?";
    isVisible: boolean = true;

    translateSubscription!: Subscription;
    visibilitySubscription!: Subscription;

    constructor(
        private config: ConfigService,
        private language: LanguageService,
        private visibility: VisibilityService ){ }

    ngOnInit() {
        this.visibilitySubscription = this.visibility.getVisibility('avatar-introduction').subscribe(visible => {
            this.isVisible = visible;
        });

        this.translateSubscription = this.language.selectedLanguage$.subscribe((selectedLanguage) => {
            const translate = this.language.getDesignTranslation(selectedLanguage.locale)
            this.helloHeader = translate.typography.helloHeader;
            this.assistHeader = translate.typography.assistHeader;
        });

    }

    ngOnDestroy() {
        if (this.translateSubscription) { this.translateSubscription.unsubscribe(); }
        if (this.visibilitySubscription) { this.visibilitySubscription.unsubscribe(); }
    }
}
