<div class="box-controls">
    <div class="controls-outside-wrapper">
        <div *ngIf="!this.hasUploadFiles() && !this.currentInputValue && !microphone.getMicrophoneStatus()" class="character-controls-container">
            <button id="id-call-ai-mobile" type="button" class="character-button" [disabled]="!isInteractionAllowed" (click)="startTalkMobile()">
                <img [src]="characterIcon" alt="Character Icon">
            </button>
        </div>
        <div class="controls-container">
            <div class="controls-inside-container">
                <div class="controls-input-container">
                    <div *ngIf="hasUploadFiles()" class="upload-file-container">
                        <div *ngFor="let file of files; let i = index">
                            <div id="id-file-item" class="file-item" tabindex="0">
                                <img *ngIf="file.type === 'image'" [src]="file.url" alt="Image thumbnail icon">
                                <div *ngIf="file.type === 'video'" class="thumbnail-video-container">
                                    <div class="play-video-icon">
                                        <app-play></app-play>
                                    </div>
                                </div>
                                <div *ngIf="file.type === 'document'" class="thumbnail-pdf-container">
                                    <div class="pdf-icon">
                                        <p>{{ file.extension }}</p>
                                    </div>
                                </div>
                                <button id="id-remove-file" type="button" class="remove-file-button" (click)="removeFile(i)">
                                    <app-close height="12" width="12"></app-close>
                                </button>
                                <p class="name-file-container">{{ file.name }}</p>
                            </div>
                        </div>
                    </div>

                    <form [style.display]="isInputShown ? 'grid' : 'none'" action="#0">
                        <textarea #textarea spellcheck="false" id="id-textarea" type="text" name="textarea" [placeholder]="currentInputPlaceholder"
                                  [disabled]="!isInteractionAllowed" [(ngModel)]="currentInputValue"
                                  (keyup.enter)="onSubmitMessage()" (keydown.enter)="$event.preventDefault()"
                                  (input)="onInput()" required>
                        </textarea>
                    </form>
                    <app-soundwave *ngIf="isSoundwaveShown"></app-soundwave>
                </div>

                <div class="buttons-container">
                    <div class="buttons-wrapper">
                        <button id="id-attachment" [disabled]="!isInteractionAllowed" type="button" class="button-att"
                                (click)="onOpenAttachments()">
                            <app-attachment></app-attachment>
                        </button>
                        <button *ngIf="!switchAction()" id="id-microphone" [disabled]="!isInteractionAllowed" type="button" class="button-mic"
                                (click)="onMicrophoneClick('click')">
                            <app-microphone></app-microphone>
                        </button>
                        <button *ngIf="switchAction()" id="id-enter" [disabled]="!isInteractionAllowed" type="button" class="button-enter"
                                (click)="onSubmitMessage()">
                            <app-enter></app-enter>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="powered-container">
        <p>{{ powerText }}</p> <img [src]="exafyImage" alt="Exafy icon"/>
    </div>
    <div class="file-selected-container">
        <input #fileInput id="file" type="file" class="input-file" (change)="onFileSelect($event)"
               accept=".jpg,.png,.jpeg,.mp4,.pdf,.txt,.docx" multiple/>
    </div>
</div>
