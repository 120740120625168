<div class="box-landing" [ngClass]="{'blurred': isBlured, 'talk-started': talkStarted}" [dir]="direction">
    <div class="gradient-landing"></div>
    <div class="inline-landing">
        <header>
            <app-header></app-header>
        </header>
        <div class="inline-content">
            <div class="scroll-container" #scrollContainer>
                <div class="conversation-container">
                    <app-introduction></app-introduction>
                    <app-conversation></app-conversation>
                </div>
                <div class="character-container">
                    <app-character></app-character>
                </div>
            </div>
            <div class="controls-container">
                <app-conversation-controls></app-conversation-controls>
            </div>
        </div>
    </div>
</div>
<app-feedback idModal="feedbackModal" labelModal="feedbacklabelModal" [dir]="direction"></app-feedback>
<app-content-modal idModal="idModal" labelModal="labelModal" [dir]="direction"></app-content-modal>
